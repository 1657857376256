let timeoutTimer;
let timerDuration = 1000; // 1 sec

const spinnerHTML = `<div class="d-flex justify-content-center">
  <div role="status" class="spinner-border text-danger">
    <span class="sr-only">Loading...</span>
  </div>
</div>`;

const debounce = function (callback, ms) {
  var timer = 0;
  return function () {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
};

function initChecked() {
  const selProductsEl = document.querySelector('#selected_products');
  const cartTable = document.querySelector('.cart__table');
  const cartTableProducts = cartTable.querySelectorAll('.product[data-id]');

  const enabledProducts = Array.from(cartTableProducts).filter((product) => {
    return product.querySelector('input[name="product-enabled"]').checked ? product : null
  })

  selProductsEl.indeterminate = false;
  if ((enabledProducts.length < cartTableProducts.length) && enabledProducts.length !== 0) {
    selProductsEl.indeterminate = true;
  } else if (enabledProducts.length === cartTableProducts.length) {
    selProductsEl.checked = true;
  } else if (enabledProducts.length === 0) {
    selProductsEl.checked = false;
  }

}


const createQueryString = (obj) => {
  const searchString = new URLSearchParams(window.location.search);
  for (key of Object.keys(obj)) {
    searchString.delete(key);
    if (obj[key]) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((val) => searchString.append(key, val));
      } else {
        searchString.append(key, obj[key].toString());
      }
    }
  }
  searchString.delete('page');
  if (searchString.toString().length >= 1) {
    return `?${searchString}`;
  } else {
    return '';
  }
}

const languages = ['en', 'ru'];
const languageURL = (url) => {
  for (const language of languages) {
    if (window.location.pathname.split('/').includes(language)) {
      url = `/${language}${url}`
    }
  }
  return url;
};
const getDatepickerLanguage = () => {
  for (const language of languages) {
    if (window.location.pathname.split('/').includes(language)) {
      return language + '-' + language.toUpperCase()
    }
  }
  return 'ua-UA';
};

const appendQuery = (paramName, paramValue) => {
  const searchString = new URLSearchParams(window.location.search);
  if (searchString.has(paramName)) {
    searchString.set(paramName, paramValue);
  } else {
    searchString.append(paramName, paramValue);
  }
  window.location.search = `?${searchString.toString()}`;
}

const getPriceSlider = () => {
  return $('.filter-price .filter-price__slider');
}

const getBrandsFilters = () => {
  return $('#brand_filter_container .input-check__input');
}

const getSelectedBrandsFilters = () => {
  return getBrandsFilters().filter((i, el) => $(el).prop('checked'))
}

const getAllVehicleFilter = () => {
  return $('#vehicle_filter_container .all-vehicles');
}

const getVehicleFilters = () => {
  return $('#vehicle_filter_container .filter-vehicle');
}


const getSelectedVehicleFilters = () => {
  return getVehicleFilters().filter((i, el) => $(el).prop('checked'))
}

(($) => {
  $(() => {

    $('[data-toggle="datepicker"]').datepicker({
      format: 'dd/mm/yyyy', language: getDatepickerLanguage()
    });

    $('.layout-switcher__button[data-layout]').each((_i, _el) => {
      const layout = localStorage.getItem('layout')
      const withFeatures = localStorage.getItem('grid-features')
      if (layout === 'grid') {
        $(`.layout-switcher__button[data-layout="grid"][data-with-features="${withFeatures}"]`).click()
        return
      }
      if ($(_el).data('layout') === layout) {
        $(_el).click()
      }
    })

    const updateCard = (cardEl) => {
      const formData = new FormData();

      $('.cart-table__row.product').each((i, product) => {
        if (product.querySelector('[name="product-enabled"]').checked) {
          formData.append('products[]', JSON.stringify({
            product_id: product.querySelector('[name="product-id"]').value,
            quality: product.querySelector('[name="product_quality"]').value
          }))
        }

      })

      timeoutTimer = setTimeout(() => {
        $.ajax({
          url: '', method: 'POST', data: formData, processData: false, contentType: false, success: (response) => {

            const {cart_block, total_block, cart_button} = response;
            $(document).find('.cart__table').replaceWith(cart_block);
            $(document).find('.cart__totals').replaceWith(total_block);
            $('#header-cart-block').html(cart_button);
            $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
              .html($(cart_button).find('.indicator__counter').text());

            // Коментим т.к. кто-то уже отключил возможность инициализации в number.js
            // Возможно позже вернёмся к базовому варианту
            // $('.input-number').customNumber();

          }, error: (err) => {
            console.log(err);
            showReqErrAlert();
          }
        })
      }, timerDuration);
    }

    // const reEnabledCard = (productCardEl) => {
    //   const productID = $(productCardEl).find('[name=product-id]').val();
    //   const productSelected = $(productCardEl).find('[name=product-enabled]').is(':checked');

    //   $.ajax({
    //     url: '', method: 'POST', data: {
    //       'action': 'checked', 'product_id': productID, 'product_selected': productSelected
    //     }, success: (response) => {
    //       const {cart_block, total_block} = response;
    //       $(document).find('.cart__table').replaceWith(cart_block);
    //       $(document).find('.cart__totals').replaceWith(total_block);
    //       initChecked();
    //     }, error: (err) => {
    //       console.log(err);
    //     },
    //   });
    // }

    const csrfToken = document
      .querySelector('[name=csrfmiddlewaretoken]')
      .getAttribute('content');

    function csrfSafeMethod(method) {
      // these HTTP methods do not require CSRF protection
      return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
    }

    $.ajaxSetup({
      beforeSend: function (xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
          xhr.setRequestHeader('X-CSRFToken', csrfToken);
        }
      },
    });

    $(document).on('click', "button[role=add-compare]", function (e) {
      const product_id = $(e.target).closest('div.product-card').data('productId');
      const data = new FormData();
      data.set('product', product_id);
      $.post('', data).then(function (response) {
        $('header #product_compare').html(response);
      }).catch(function (error) {
        console.log("ERROR", error.message)
      })
    });

    $(document).on('click', '.dropdown-menu[aria-labelledby="selectContractBtn"] .dropdown-item, #mobileSelectContract [data-contract_id]', function (e) {
      e.preventDefault()
      const formData = new FormData();
      formData.set('selected-contract', $(e.target).data('contract_id'));
      formData.set('reqUrl', location.pathname);

      $.ajax({
        type: 'POST',
        url: '/set-contract/',
        data: formData,
        processData: false,
        contentType: false,
        mimeType: 'multipart/form-data',

        success: function (resp) {
          const response = JSON.parse(resp);

          setTimeout(() => {
            if (response.message === 'success' && response.location) {
              location.reload();
            }
          }, 1000)

        },
        error: function (err) {
          console.log(err);
        }
      })
    });

    $(document).on('change', 'input[name="contract"], .vehicles-list__item', (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.set('selected-contract', e.target.value);
      formData.set('reqUrl', location.pathname);

      $.ajax({
        url: '/set-contract/',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        mimeType: 'multipart/form-data',
        success: (resp) => {
          const response = JSON.parse(resp);
          if (response.message === 'success') {
            window.location.href = response.location;
          }
        },
        error: (err) => {
          console.log(err);
          showReqErrAlert();
        },
      });
    });

    $(document).on('click', '.currency', (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.set('selected-currency', $(e.target).data('currency-id'));

      $.ajax({
        url: '/change-currency/',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        mimeType: 'multipart/form-data',
        success: (resp) => {
          const response = JSON.parse(resp);
          if (response) {
            $('.currency_menu .topbar__button-title').html(response.current_symbol);
            if ($(e.target)
              .closest('.currency_menu')
              .hasClass('topbar__menu--open')) {
              $(e.target)
                .closest('.currency_menu')
                .removeClass('topbar__menu--open');
            }
          }
        },
        error: (err) => {
          console.log(err);
          showReqErrAlert();
        },
      });
    });

    // Удаление товара из списка желаний
    $(document).on('click', '.wishlist__remove', (e) => {
      e.preventDefault();
      var actionBtn = $(e.target).data('action')
      var productID = $(e.target).closest('.wishlist__column--remove').data('productId')


      if (actionBtn === undefined) {
        actionBtn = 'remove-wishlist'
      }


      $.ajax({
        url: languageURL('/wishlist/'),
        method: 'POST',
        data: {'action': actionBtn, 'product_id': productID},
        success: (response) => {
          const {products_wishlist} = response;

          $('.wishlist').html(products_wishlist);

        },
        error: (errors) => {
          console.log(errors);
          showReqErrAlert();
        }
      })

    })

    // Добавление товара в корзину из списка желаний.
    $(document).on('click', '.add-to-cart-wishlist', (e) => {
      e.preventDefault();
      const actionBtn = $(e.target).data('action');
      const productID = $(e.target).closest('.wishlist__column--button').data('productId');
      const quantities = $(e.target).data('piece')
      console.log(quantities)

      $.ajax({
        url: languageURL('/cart/'),
        method: 'POST',
        data: {'action': actionBtn, 'product_id': productID, 'quantities': quantities},
        success: (response) => {
          const {products_wishlist, cart_button} = response;

          $(`.wishlist__row[data-id='${productID}']`).replaceWith(products_wishlist);
          $('#header-cart-block').html(cart_button);
          $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
            .html($(cart_button).find('.indicator__counter').text());
        },
        error: (errors) => {
          console.log(errors);
          showReqErrAlert();
        }
      })
    })

    // Добавление товара в корзину из списка сравнения.
    $(document).on('click', '.add-to-cart-compare', (e) => {
      e.preventDefault();
      const actionBtn = $(e.target).data('action');
      const productID = $(e.target).closest('.compare-table__column--product').data('productId');
      const quantities = $(e.target).data('piece')
      // console.log(quantities)

      $.ajax({
        url: languageURL('/cart/'),
        method: 'POST',
        data: {'action': actionBtn, 'product_id': productID, 'quantities': quantities},
        success: (response) => {
          const {product_compare, cart_button} = response;


          $('#header-cart-block').html(cart_button);
          $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
            .html($(cart_button).find('.indicator__counter').text());
          //$('.card-table').html(product_compare); ОШИБКА
          $('.compare .table-prodects-compare').html(product_compare);


        },
        error: (errors) => {
          console.log(errors);
          showReqErrAlert();
        }
      })
    })

    // Добавление товара в корзину
    $(document).on('click', '.product-card__addtocart-icon, .product-card__addtocart-full', (e) => {
      e.preventDefault();
      const btn = $(e.target).closest('[data-action]');
      const productItem = btn.closest('.products-list__item');
      const actionBtn = btn.data('action');
      const productID = productItem.data('productId');
      const contract = btn.data('contract');
      let quantities = $(btn).data('piece');
      const productItemQuantityInput = productItem.find('[name=product_quantity]');
      if (productItemQuantityInput.length && productItemQuantityInput.val()) {
        quantities = productItem.find('[name=product_quantity]').val();
      }


      if (contract !== 'None') {

        $.ajax({
          url: languageURL('/cart/'),
          method: 'POST',
          data: {'action': actionBtn, 'product_id': productID, 'quantities': quantities},
          success: (response) => {
            const {product_cart, cart_button} = response;


            $(`[data-product-id='${productID}']`).replaceWith(product_cart);
            $('#header-cart-block').html(cart_button);
            $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
              .html($(cart_button).find('.indicator__counter').text());

          },
          error: (errors) => {
            console.log(errors);
            showReqErrAlert();
          }
        })
      }
    })

    // Добавление товара в корзину из детального обзора.
    $(document).on('click', 'button.add_a_product_card', (e) => {
      e.preventDefault()
      const actionElem = $(e.target).closest('.product__actions');
      const productQuantity = $(actionElem).find('[name=product_quantity]').val();
      const actionBtn = $(e.target).data('action');
      const placeRequest = $(e.target).closest('.container').data('placeRequest');
      const productID = $(e.target).closest('.block-split').data('productId');
      const contractID = $(e.target).data('contractId')

      if (contractID !== 'None') {
        $.ajax({
          url: languageURL('/cart/'), method: 'POST', data: {
            'action': actionBtn, 'product_id': productID, 'place_request': placeRequest, 'quantities': productQuantity,
          }, success: (response) => {
            const {product, cart_button} = response;


            $('.product__info').html(product);
            $('#header-cart-block').html(cart_button);
            $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
              .html($(cart_button).find('.indicator__counter').text());

          }, error: (errors) => {
            console.log(errors);
            showReqErrAlert();
          }
        })
      }
    })

    // Добавление продукта в список желаемого
    $(document).on('click', 'button.product-card__action--wishlist, .product-card__wishlist, #add-to-wishlist', (e) => {
      e.preventDefault();
      const btnEl = $(e.target).closest('button');
      $(btnEl).toggleClass('active');
      const actionBtn = $(btnEl).data('action');
      const productID = $(e.target).closest('.products-list__item').data('productId');
      const contract = $(btnEl).data('contractId');

      if (Number(contract)) {
        $.ajax({
          url: languageURL('/wishlist/'),
          method: 'POST',
          data: {'action': actionBtn, 'product_id': productID},
          success: (response) => {
            const {product_cart, cart_button} = response;


            $(`[data-product-id='${productID}']`).replaceWith(product_cart);
            $('#header-cart-block').html(cart_button);
            $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
              .html($(cart_button).find('.indicator__counter').text());

          },
          error: (errors) => {
            console.log(errors);
            showReqErrAlert();
          }
        })
      }
    });

    // Добавление товара в список желания из страницы товара
    $(document).on('click', '.product__actions-item--wishlist', (e) => {
      e.preventDefault();
      const btnEl = $(e.target).closest('button');
      const actionBtn = $(btnEl).data('action');
      const productID = $(e.target).closest('[data-product-id]').data('productId');
      const placeRequest = $(e.target).closest('.container').data('placeRequest');
      const contract = $(btnEl).data('contractId');


      if (contract !== 'None') {
        $(btnEl).toggleClass('active');
        $.ajax({
          url: languageURL('/wishlist/'), method: 'POST', data: {
            'action': actionBtn, 'product_id': productID, 'place_request': placeRequest,
          }, success: (response) => {
            const {product_button} = response;


            $(e.target).closest('.product__info-card').replaceWith(product_button);

          }, error: (errors) => {
            console.log(errors);
            showReqErrAlert();
          }
        })
      }
    });

    // Добавление продукта в список сравнения
    $(document).on('click', 'button.product-card__compare, #add-to-compare', (e) => {
      e.preventDefault();
      const btnEl = $(e.target).closest('button');
      $(btnEl).toggleClass('active');
      const actionBtn = $(e.target).closest('button').data('action');
      const productID = $(e.target).closest('.products-list__item').data('productId');
      const contract = $(btnEl).data('contractId');

      if (Number(contract)) {
        $.ajax({
          url: languageURL('/product-compare/'),
          method: 'POST',
          data: {'action': actionBtn, 'product_id': productID},
          success: (response) => {
            const {product_cart, header} = response;

            $('#header-compare-block').html(header);
            $(`[data-product-id='${productID}']`).replaceWith(product_cart);
          },
          error: (errors) => {
            console.log(errors);
            showReqErrAlert();
          }
        })
      }
    });

    // Добавление продукта в список сравнения из детального обзора
    $(document).on('click', 'button.product__actions-item--compare', (e) => {
      e.preventDefault();
      const btnEl = $(e.target).closest('button');
      const actionBtn = $(e.target).closest('button').data('action');
      const placeRequest = $(e.target).closest('.container').data('placeRequest');
      const productID = $(e.target).closest('[data-product-id]').data('productId');
      const contract = $(btnEl).data('contractId');

      if (contract !== 'None') {
        $(btnEl).toggleClass('active');
        $.ajax({
          url: languageURL('/product-compare/'), method: 'POST', data: {
            'action': actionBtn, 'product_id': productID, 'place_request': placeRequest,
          }, success: (response) => {
            const {product_button, header} = response;

            $('#header-compare-block').html(header);
            $(e.target).closest('.product__info-card').replaceWith(product_button);
          }, error: (errors) => {
            console.log(errors);
            showReqErrAlert();
          }
        })
      }
    });

    // Удаление продукта из списка сравнения.
    $(document).on('click', 'button.btn-secondary-compare', (e) => {
      e.preventDefault();
      const actionBtn = $(e.target).closest('button').data('action');
      const productID = $(e.target).closest('.compare-table__column--product').data('productId');

      $.ajax({
        url: languageURL('/product-compare/'),
        method: 'POST',
        data: {'action': actionBtn, 'product_id': productID},
        success: (response) => {
          const {product_compare, header} = response;

          $('#header-compare-block').html(header);
          // $('.card-table').html(product_compare); ОШИБКА
          $('.compare .table-prodects-compare').html(product_compare);
        },
        error: (errors) => {
          console.log(errors);
          showReqErrAlert();
        }
      })

    })

    // Для страницы checkout page
    $(document).on('change', '.payment-methods__item input[type=radio]', (e) => {
      const targetForm = 'form[name=payment_method__' + $(e.target).attr('id') + ']';
      $.each($('form[name^="payment_method__"]'), (_, value) => {
        if ($(value).hasClass('d-none') === false) {
          $(value).addClass('d-none');
        }
      })
      if ($(targetForm)) $(targetForm).toggleClass('d-none');

      const documentsAvailable = ['delivery']
      if (documentsAvailable.includes($(e.target).attr('id'))) {
        $('#checkout-documents-wrapper').removeClass('d-none')
      } else {
        $('#checkout-documents-wrapper').addClass('d-none')
      }
    });

    // Инициализируем при загрузке страницы выбранную доставку
    if (location.pathname.includes('checkout')) {
      if ($('.payment-methods__list').length) {
        // Активируем --active Тип доставки.
        $('.payment-methods__list .payment-methods__item--active input[type=radio]').trigger('click');
        // ИЛИ Активируем чекнутый Тип доставки.
        // $('.payment-methods__list input[name="checkout_payment_method"]:checked').trigger('click').trigger('change');
      }
    }

    //---

    // Для страницы товара добавление в корзину.

    // Валидация
    const validateNumber = (el) => {
      const isCorrect = /^[1-9]/g.test($(el).val());
      let max = $(el).data('maxValue') + ''
      max = max.slice(0, max.indexOf(',')) + '.' + max.slice(max.indexOf(',') + 1)
      if (!isCorrect) {
        $(el).val($(el).attr('min') || 1);
        console.log('wrong')
      }
      if ($(el).val() > +max) {
        $(el).val(+max)
      }
      if ($(el).val() % $(el).data('piece') !== 0) {
        $(el).val(Math.floor($(el).val() / $(el).data('piece')) * $(el).data('piece'))
      }
    }
    const changeNumberValue = (e, action) => {
      const el = $(e.target).parent().find('.input-number__input');
      const piece = $(el).data('piece')
      if (action === 'add') {
        $(el).val(+$(el).val() + piece);
      }
      if (action === 'subtract') {
        $(el).val(+$(el).val() - piece);
      }
      validateNumber(el);
      $(el).trigger('change');
    }

    $(document).on('click', '.input-number__add', (e) => {
      changeNumberValue(e, 'add');
    });

    $(document).on('click', '.input-number__sub', (e) => {
      changeNumberValue(e, 'subtract');
    });

    $(document).on('change', '.input-number__input', (e) => {
      const el = $(e.target).parent().find('.input-number__input');
      validateNumber(el);
    })

    $(document).on('change', '.container[data-place-request="product_detail"] .input-product-quantity', (e) => {
      e.preventDefault();
      if ($('.add_a_product_card').data('action') === 'remove-cart') {
        const formData = new FormData();
        const placeRequest = $(e.target).closest('.container').data('placeRequest');
        const productID = $(e.target).closest('[data-product-id]').data('productId');
        const quantity = $('.input-product-quantity').val();

        formData.append('products[]', JSON.stringify({
          product_id: productID, quality: quantity, place_request: placeRequest,
        }))


        $.ajax({
          url: languageURL('/cart/'),
          method: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          success: (response) => {

            const {cart_button} = response;
            $('#header-cart-block').html(cart_button);
            $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
              .html($(cart_button).find('.indicator__counter').text());

          },
          error: (err) => {
            console.log(err);
            showReqErrAlert();
          }
        })
      }
    })

    $(document).on('change', '.container[data-place-request!="product_detail"] input[name=product_quality]', (e) => {
      e.preventDefault();
      clearTimeout(timeoutTimer);

      updateCard();
    })

    //Удаление чекнутых товаров из корзины
    $(document).on('click', '.cart-table__remove-checked', function (e) {
      $.ajax({
        url: languageURL('/cart/'), method: 'POST', data: {
          'action': 'remove-checked'
        }, success: function success(response) {
          var cart_block = response.cart_block, total_block = response.total_block, cart_button = response.cart_button;
          $(document).find('.cart__table').replaceWith(cart_block);
          $(document).find('.cart__totals').replaceWith(total_block);
          $('#header-cart-block').html(cart_button);
          initChecked();
        }, error: function error(err) {
          console.log(err);
          showReqErrAlert();
        }
      });
    });

    // Изменение колличества товара в корзине из каталога
    $(document).on('change', '.products-view .input-product-quantity', function (e) {
      e.preventDefault();
      if ($(this).closest('.product-card__footer').find('[data-action="remove-cart"]').length) {
        clearTimeout(timeoutTimer);
        var formData = new FormData();
        $('.product-card__addtocart-icon[data-action="remove-cart"]').each(function (i, el) {
          const productItem = $(el).closest('.products-list__item');
          const productId = productItem.data('productId');
          const productQuantity = productItem.find('input[name=product_quantity]').val();
          formData.append('products[]', JSON.stringify({
            product_id: productId, quality: productQuantity
          }));
        });
        timeoutTimer = setTimeout(function () {
          $.ajax({
            url: '/cart/',
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function success(response) {
              var cart_block = response.cart_block, cart_button = response.cart_button;
              $('#header-cart-block').html(cart_button);
              $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
                .html($(cart_button).find('.indicator__counter').text());
            },
            error: function error(err) {
              console.log(err);
              showReqErrAlert();
            }
          });
        }, timerDuration);
      }
    })

    // Выставляем состояние общему чекбоксу в корзине при загрузке корзины
    if (location.pathname.includes('cart') && $('#selected_products').length) {
      initChecked();
    }

    // Отправляем отмеченные товары в корзине на сервер и перерисовываем DOM
    const reEnabledCard = () => {
      let products_arr = [];
      $('#cart-list input[name=product-enabled]:not([disabled])').each(function (i, el) {
        let productID = $(el).siblings('[name="product-id"]').val();
        let productSelected = $(el).is(':checked');
        products_arr.push({'product_id': productID, 'selected': productSelected});
      });
      $.ajax({
        url: '', method: 'POST', data: {
          'action': 'checked', 'products_arr': JSON.stringify(products_arr),
        }, success: (response) => {
          const {cart_block, total_block, cart_button} = response;
          $(document).find('.cart__table').replaceWith(cart_block);
          $(document).find('.cart__totals').replaceWith(total_block);
          $('#header-cart-block').html(cart_button);
          initChecked();
        }, error: (err) => {
          console.log(err);
          showReqErrAlert();
        },
      });
    }

    // Выбор ВСЕ товаров для оформления заказа
    $(document).on('click', '#selected_products', function (e) {
      // var productsRowEl = $('.product');
      if ($(e.target).prop("checked")) {
        $('input[name=product-enabled]:not([disabled])').prop('checked', 'checked');
      } else {
        $('input[name=product-enabled]:not([disabled])').prop('checked', false);
      }

      reEnabledCard();
    });

    // Переключатель для одного товара
    $(document).on('change', 'input[name=product-enabled]', function (e) {
      // var productRowEl = $(e.target).closest('.product');
      var tableProductRows = $(e.target).closest('.cart-table__table').find('tbody');
      var tableHeadCheckedInputEl = $(e.target).closest('.cart-table__table').find('thead').find('input#selected_products');

      var allProductRows = $(tableProductRows).find('input[name=product-enabled]');
      var enabled = allProductRows.filter(function (i, product) {
        return !!$(product).prop('checked');
      });
      if (enabled.length < allProductRows.length && enabled.length !== 0) {
        $('#selected_products').prop({
          indeterminate: true, checked: false
        });
      } else if (enabled.length === allProductRows.length) {
        $('#selected_products').prop({
          indeterminate: false, checked: true
        });
      } else if (enabled.length === 0) {
        $('#selected_products').prop({
          indeterminate: false, checked: false
        });
      }
      reEnabledCard();
    });


    //===============================  ФИЛЬТРЫ  =====================================
    $('#product_filter .filter-search__input').on('keyup', debounce((e) => {
      $('#product_filter .filter-list__item[data-toggle="popover"]').popover('hide');
      const filter = $(e.target).closest('.filter');
      const filter_search_string = $(e.target).val().toLowerCase();
      filter.find('.filter-list__item').each(function (i, el) {
        const item_val = $(el).find('.input-check__input').data('propertyValues').toString().toLowerCase();
        if (!item_val.includes(filter_search_string)) $(el).addClass('d-none'); else $(el).removeClass('d-none');
      });
    }, 500));
    //------
    // Действие на изменение состояния чекбокса product_brands
    // $(document).on('change', 'input[name=product_brands]', (e) => {
    //   e.preventDefault();
    //
    //   const brokenRequest = location.href.split('/')
    //   const categoryNameIndex = brokenRequest.length - 2
    //   const formData = new FormData();
    //
    //   formData.set('name_brand', $(e.target).data('brand'));
    //   formData.set('name_category', brokenRequest[categoryNameIndex]);
    //
    //   $.ajax({
    //     url: '',
    //     type: 'POST',
    //     data: formData,
    //     processData: false,
    //     contentType: false,
    //     mimeType: 'multipart/form-data',
    //     success: (response) => {
    //
    //       const {cart_block, total_block, cart_button} = response;
    //       $('.cart').html(cart_block + total_block);
    //       $('#header-cart-block').html(cart_button);
    //
    //     },
    //     error: (errors) => {
    //       console.log(errors)
    //     }
    //   })
    // });
    // -------
    // Инициализация popover для фильтров
    $('#product_filter .filter-list__item[data-toggle="popover"]').popover({
      trigger: 'manual',
      content: spinnerHTML,
      template: '<div class="popover popover_filters" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
      html: true,
    });

    // Обработчик скрытия popover-ов фильтров при кликах на различные эллементы
    $('.sidebar__backdrop, .sidebar__close, #product_filter [data-collapse-trigger], #product_filter #btn-reset-filter')
      .on('click', function () {
        $('#product_filter .filter-list__item[data-toggle="popover"]').popover('hide');
      });

    // Действие на изменение состояния чекбокса product_filter
    $(document).on('change', '#product_filter input[name=product_filter]', (e) => {
      //---
      const item_pop_el = e.target.closest('.filter-list__item');
      $('#product_filter .filter-list__item[data-toggle="popover"]').popover('hide');
      $(item_pop_el).popover('show');
      //---
      const params = {action: 'select_filter'};
      $('#product_filter input[name="product_filter"]')
        .filter((i, el) => $(el).prop('checked'))
        .each((i, el) => {
          const filter_prop_name = $(el).data('propertyName');
          const filter_prop_val = $(el).data('propertyValues');
          if (filter_prop_name && filter_prop_val) {
            if (!(filter_prop_name in params)) params[filter_prop_name] = [];
            params[filter_prop_name].push(filter_prop_val);
          }
        });
      const _searchString = new URLSearchParams(window.location.search);
      if (_searchString.has('search-string')) {
        params['search-string'] = _searchString.get('search-string');
      }

      const searchString = new URLSearchParams('');
      for (key of Object.keys(params)) {
        if (params[key] && Array.isArray(params[key])) {
          params[key].forEach((val) => searchString.append(key, val));
        } else {
          searchString.append(key, params[key].toString());
        }
      }
      const url = window.location.pathname + '?' + searchString;
      // console.log('url', url);
      // console.log('Длина строки запроса:', url.length);
      $.ajax({
        url: url, type: 'GET', mimeType: 'application/json', success: (response) => {
          const {text, status} = response;
          if (status === 'success') {
            $('#' + $(item_pop_el).attr('aria-describedby')).find('.popover-body').html(text);
          } else {
            $('#product_filter .filter-list__item[data-toggle="popover"]').popover('hide');
          }
        }, error: (err) => {
          $(item_pop_el).popover('hide');
          console.log(err);
          showReqErrAlert();
        },
      });
    });

    // Действие на Кнопку Очистки Фильтров
    // $(document).on('click', '#btn-reset-filter', (e) => {
    //   // Очистка Брендов
    //   getSelectedBrandsFilters().each((i, el) => $(el).prop('checked', false));
    //   // Очистка Транспортов
    //   getVehicleFilters().each((i, el) => $(el).prop('checked', false));
    //   $(getAllVehicleFilter()).prop('checked', true);
    //   // Скидание Слайдера Цены
    //   const priceSlider = $(getPriceSlider())[0];
    //   if (priceSlider && 'noUiSlider' in priceSlider) {
    //     priceSlider.noUiSlider.reset();
    //   }
    //   // Скидание Квери Параметров страницы
    //   const searchString = new URLSearchParams(window.location.search);
    //   const params = ['bid', 'vid', 'min_price', 'max_price', 'page'];
    //   for (const param of params) {
    //     searchString.delete(param);
    //   }
    //   const isChanged = window.location.search.toString().slice(1) !== searchString.toString();
    //   if (isChanged) {
    //     window.location.search = `${searchString}`;
    //   }
    // })
    $(document).on('click', '#product_filter #btn-reset-filter', (e) => {
      const searchString = new URLSearchParams(window.location.search);
      let params = ['min_price', 'max_price', 'page'];
      // Очистка Динамических фильтров
      $('#product_filter input[name="product_filter"]')
        .filter((i, el) => $(el).prop('checked'))
        .each((i, el) => {
          const filter_prop_name = $(el).data('propertyName');
          if (filter_prop_name && !params.includes(filter_prop_name)) params.push(filter_prop_name);
          $(el).prop('checked', false);
        });
      // Скидание Слайдера Цены
      const priceSlider = $(getPriceSlider())[0];
      if (priceSlider && 'noUiSlider' in priceSlider) {
        priceSlider.noUiSlider.reset();
      }
      // Скидание Квери Параметров страницы
      for (const param of params) {
        searchString.delete(param);
      }
      const isChanged = window.location.search.toString().slice(1) !== searchString.toString();
      if (isChanged) {
        window.location.search = `${searchString}`;
      }
    })
    // Действие на Кнопку Применения Фильтров
    // $(document).on('click', '#btn-apply-filter', (e) => {
    //   // Выбираю значение брендов и автомобилей
    //   const brandIDs = Array.from(getSelectedBrandsFilters().map((i, el) => $(el).data('id')));
    //   const vehicleIDs = Array.from(getSelectedVehicleFilters().map((i, el) => $(el).data('id')));
    //   // Создаю обьект для квери параметров
    //   const params = {
    //     'bid': (brandIDs.length >= 1) ? brandIDs : null, 'vid': (vehicleIDs.length >= 1) ? vehicleIDs : null,
    //   }
    //   // Проверяю есть ли ценовой ползунок (у не зарегистрированных пользователей его не будет) и если он есть, тогда уже проверяю его значения и кладу в обьект
    //   if ($(getPriceSlider())[0]) {
    //     const priceFilter = $(getPriceSlider())[0].closest('.filter-price');
    //     const [minPrice, maxPrice] = $(getPriceSlider())[0].noUiSlider.get();
    //     const initialMinPrice = $(priceFilter).data('min');
    //     const initialMaxPrice = $(priceFilter).data('max');
    //     params['min_price'] = (+minPrice !== +initialMinPrice) ? minPrice : null;
    //     params['max_price'] = (+maxPrice !== +initialMaxPrice) ? maxPrice : null;
    //   }
    //   window.location.search = createQueryString(params);
    // })
    $(document).on('click', '#product_filter #btn-apply-filter', (e) => {
      let params = {};
      $('#product_filter input[name="product_filter"]')
        // .filter((i, el) => $(el).prop('checked'))
        .each((i, el) => {
          // if(!($(el).attr('name') in params)) params[$(el).attr('name')] = [];
          // params[$(el).attr('name')].push($(el).data('id'));
          const filter_prop_name = $(el).data('propertyName');
          const filter_prop_val = $(el).data('propertyValues');
          if (filter_prop_name && filter_prop_val) {
            if (!(filter_prop_name in params)) params[filter_prop_name] = [];
            if ($(el).prop('checked')) params[filter_prop_name].push(filter_prop_val);
          }
        });
      // Проверяю есть ли ценовой ползунок (у не зарегистрированных пользователей его не будет) и если он есть, тогда уже проверяю его значения и кладу в обьект
      if ($(getPriceSlider())[0]) {
        const priceFilter = $(getPriceSlider())[0].closest('.filter-price');
        const [minPrice, maxPrice] = $(getPriceSlider())[0].noUiSlider.get();
        const initialMinPrice = $(priceFilter).data('min');
        const initialMaxPrice = $(priceFilter).data('max');
        params['min_price'] = (+minPrice !== +initialMinPrice) ? minPrice : null;
        params['max_price'] = (+maxPrice !== +initialMaxPrice) ? maxPrice : null;
      }
      window.location.search = createQueryString(params);
      // console.log('Длина строки запроса:', window.location.toLocaleString().length);
    })

    // Действия Фильтрации Транспорта
    $(document).on('change', '#vehicle_filter_container .all-vehicles', (e) => {
      getSelectedVehicleFilters().each((i, el) => $(el).prop('checked', false));
      $(e.target).prop('checked', true);
    })

    $(document).on('click', '#vehicle_filter_container .filter-vehicle', (e) => {
      if (!$(e.target).prop('checked') && getSelectedVehicleFilters().length === 0) {
        $(getAllVehicleFilter()).prop('checked', true);
        return
      }
      $(getAllVehicleFilter()).prop('checked', false);
    })

    // Фильтр цены
    // Минимальной цены
    $(document).on('change', '#filter-price .filter-price__min-value', (e) => {
      const value = $(e.target).val();
      getPriceSlider()[0].noUiSlider.set([value.toString(), null]);
    });
    // Максимальной цены
    $(document).on('change', '#filter-price .filter-price__max-value', (e) => {
      const value = $(e.target).val();
      getPriceSlider()[0].noUiSlider.set([null, value.toString()]);
    })
    //================================================================================


    // Удаление товара из корзины на странице корзины
    // $(document).on('click', '.cart-table__remove', (e) => {
    //   e.preventDefault();
    //   const actionBtn = $(e.target).closest('.cart-table__column').data('action');
    //   const productID = $(e.target).closest('.cart-table__column').data('productid');
    //   $.ajax({
    //     url: languageURL('/cart/'),
    //     method: 'POST',
    //     data: {'action': actionBtn, 'product_id': productID},
    //     success: (response) => {

    //       const {cart_block, total_block, cart_button} = response;
    //       $('.cart').html(cart_block + total_block);
    //       $('#header-cart-block').html(cart_button);
    //       $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
    //           .html($(cart_button).find('.indicator__counter').text());

    //     },
    //     error: (errors) => {
    //       console.log(errors);
    //       showReqErrAlert();
    //     }
    //   })
    // })

    // Удаление товара из корзины в блоке dropcart (в шапке) и на странице корзины
    $(document).on('click', '.dropcart__item-remove, .cart-table__remove', (e) => {
      e.preventDefault();
      let actionBtn, productID;
      if ($(e.currentTarget).hasClass('dropcart__item-remove')) {
        actionBtn = $(e.currentTarget).data('action');
        productID = $(e.currentTarget).closest('.dropcart__item').data('productid');
      }
      if ($(e.currentTarget).hasClass('cart-table__remove')) {
        actionBtn = $(e.currentTarget).closest('.cart-table__column').data('action');
        productID = $(e.currentTarget).closest('.cart-table__column').data('productid');
      }
      const placeRequest = $('.container[data-place-request]').data('placeRequest');
      let req_data = {'action': actionBtn, 'product_id': productID};
      if (placeRequest && placeRequest === 'product_detail') req_data.place_request = placeRequest;

      $.ajax({
        url: languageURL('/cart/'), method: 'POST', data: req_data, success: (response) => {

          const {
            cart_button, cart_block, total_block, product_cart, product_compare, products_wishlist, product
          } = response;

          // Перересовка кнопки корзины в шапке
          if (cart_button) {
            $('#header-cart-block').html(cart_button);
            $('.mobile-header__body .mobile-indicator--cart .mobile-indicator__counter')
              .html($(cart_button).find('.indicator__counter').text());
          }
          // Перересовка таблицы на странице корзины
          if (cart_block && total_block) {
            $('.cart').html(cart_block + total_block);
          }
          // Перересовка продукта на странице каталога
          if (product_cart) {
            $(`.products-view .products-list__item[data-product-id="${productID}"]`).replaceWith(product_cart);
          }
          // Перересовка продукта на странице сравнения
          if (product_compare) {
            const compare = $('.compare');
            if (compare.length) {
              compare.find('.table-prodects-compare').html(product_compare);
            }
          }
          // Перересовка продукта на странице желаний
          if (products_wishlist) {
            const wishlist = $('.wishlist');
            if (wishlist.length) {
              wishlist.find(`.wishlist__row[data-id="${productID}"]`).replaceWith(products_wishlist);
            }
          }
          // Перересовка продукта в карточке плодукта
          if (product) {
            const product__body = $('.product__body');
            if (product__body.length) {
              const product__id = product__body.closest('[data-product-id]').data('productId');
              if (product__id == productID) $('.product__info').html(product);
            }
          }
        }, error: (errors) => {
          console.log(errors);
          showReqErrAlert();
        }
      })
    })

    // Действие - Открыть Индикатор (Логин, Корзина)
    $(document).on('click', '.header__indicators .indicator .indicator__button', (e) => {
      $(e.target).closest('.indicator').toggleClass('indicator--open');
    });

    $(document).on('click', function (event) {
      $('.indicator')
        .not($(event.target).closest('.indicator'))
        .removeClass('indicator--open');
    });

    // Динамическая загрузка городов доставки в оформление заказа
    $('.form-control-select2-ajax').select2({
      ajax: {
        // url: '',
        url: function (params) {
          return $(this).data('searchUrl') ? $(this).data('searchUrl') : '';
        }, dataType: 'json', delay: 250, data: function (params) {
          return {
            action: $(this).data('searchAction'), q: params.term,
          };
        }, cache: true
      }, minimumInputLength: 3, width: ''
    });

    // Обработчик изменения области доставки -> отображаем районы (для Киева отделения) в оформление заказа
    // $(document).on('change', '#checkout-delivery-region, #checkout-dropshipping-region', (e) => {
    //   e.preventDefault();
    //   const activeForm = $(e.target).closest('form:not(.d-none)');
    //   const formData = new FormData();
    //   formData.set('selected-region', e.target.value.split(',')[0]);
    //   formData.set('action', e.target.name);
    //   const cityKiev = ['Місто Київ', 'Сity Kyiv', 'Город Киев']
    //   const isKiev = !!(cityKiev.filter(el => $(e.target).find('option:selected').text() === el).length || 0)

    //   // Очистка Городов и Отделений
    //   activeForm.find('select.select-city option[value != ""]').remove();
    //   activeForm.find('select.select-department option[value != ""]').remove();

    //   if (isKiev) {
    //     $(activeForm).find('select.select-district').closest('.form-group').hide();
    //     $(activeForm).find('select.select-city').closest('.form-group').hide();
    //     $.ajax({
    //       url: '',
    //       type: 'POST',
    //       data: formData,
    //       processData: false,
    //       contentType: false,
    //       mimeType: 'multipart/form-data',
    //       success: (response) => {
    //         const html_data = JSON.parse(response).departments;
    //         $(activeForm)
    //           .find('select.select-department')
    //           .closest('.form-group').replaceWith(html_data);
    //         $(activeForm).find('select.form-control-select2.select-department').select2();
    //       },
    //       error: (err) => {
    //         console.log(err);
    //         showReqErrAlert();
    //       },
    //     });
    //   } else {
    //     $(activeForm).find('select.select-district').closest('.form-group').show();
    //     $(activeForm).find('select.select-city').closest('.form-group').show();

    //     $.ajax({
    //       url: '',
    //       type: 'POST',
    //       data: formData,
    //       processData: false,
    //       contentType: false,
    //       mimeType: 'multipart/form-data',
    //       success: (response) => {
    //         const html_data = JSON.parse(response).districts;
    //         $(activeForm)
    //           .find('select.select-district')
    //           .closest('.form-group').replaceWith(html_data);
    //         $(activeForm).find('select.form-control-select2.select-district').select2();
    //       },
    //       error: (err) => {
    //         console.log(err);
    //         showReqErrAlert();
    //       },
    //     });

    //   }
    // })

    // Обработчик изменения района доставки -> отображаем населённые пункты в оформление заказа
    // $(document).on('change', '#checkout-delivery-district, #checkout-dropshipping-district', (e) => {
    //   e.preventDefault();
    //   const activeForm = $(e.target).closest('form:not(.d-none)');
    //   const formData = new FormData();
    //   formData.set('selected-district', e.target.value);
    //   formData.set('action', e.target.name);

    //   // Очистка Отделений
    //   activeForm.find('select.select-department option[value != ""]').remove();

    //   $.ajax({
    //     url: '',
    //     type: 'POST',
    //     data: formData,
    //     processData: false,
    //     contentType: false,
    //     mimeType: 'multipart/form-data',
    //     success: (response) => {
    //       const html_data = JSON.parse(response).localities;
    //       $(activeForm)
    //         .find('select.select-city')
    //         .closest('.form-group').replaceWith(html_data);
    //       $(activeForm).find('select.form-control-select2.select-city').select2();
    //     },
    //     error: (err) => {
    //       console.log(err);
    //       showReqErrAlert();
    //     },
    //   });
    // })

    // Обработчик изменения города доставки -> отображаем почтовые отделений в оформление заказа
    $(document).on('change', '#checkout-delivery-city, #checkout-dropshipping-city', (e) => {
      e.preventDefault();
      const activeForm = $(e.target).closest('form:not(.d-none)');
      const formData = new FormData();
      formData.set('selected-city', e.target.value);
      formData.set('action', e.target.name);

      $.ajax({
        url: '',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        mimeType: 'multipart/form-data',
        success: (response) => {
          const html_data = JSON.parse(response).departments;
          $(activeForm)
            .find('select.select-department')
            .closest('.form-group').replaceWith(html_data);
          $(activeForm).find('select.form-control-select2.select-department').select2();
        },
        error: (err) => {
          console.log(err);
          showReqErrAlert();
        },
      });
    })

    // Валидация цены для формы дропшиппинга
    $(document).on('change', '#checkout-dropshipping-price', (e) => {
      const minVal = Number($(e.target).data('minValue'));
      const currVal = Number($(e.target).val());
      if (currVal < minVal) {
        $(e.target).val(minVal)
      }
    })

    // Валидация полей формы оформления заказа
    const validateForm = (form) => {
      let form_valid = true;
      let form_inpunts = $(form).find('input, select, textarea').removeClass('is-invalid');
      form_inpunts.filter('[required]').each(function (i, el) {
        if (!$(el).val()) {
          $(el).addClass('is-invalid');
          form_valid = false;
        }
      })
      form_inpunts.filter('[pattern]').each(function (i, el) {
        let regexp = new RegExp($(el).attr('pattern'));
        let val = $(el).val();
        if (!regexp.test(val)) {
          $(el).addClass('is-invalid');
          form_valid = false;
        }
      })
      return form_valid;
    }

    $(document).on('change', 'form.payment-form:not(.d-none) .is-invalid', (e) => {
      let pattern = $(e.target).attr('pattern');
      let val = $(e.target).val();
      if (pattern) {
        let regexp = new RegExp(pattern);
        if (regexp.test(val)) $(e.target).removeClass('is-invalid');
      } else if (val) $(e.target).removeClass('is-invalid');
    })

    $(document).on('keyup', 'form.payment-form:not(.d-none) .is-invalid', (e) => {
      let pattern = $(e.target).attr('pattern');
      let val = $(e.target).val();
      if (pattern) {
        let regexp = new RegExp(pattern);
        if (regexp.test(val)) $(e.target).removeClass('is-invalid');
      } else if (val) $(e.target).removeClass('is-invalid');
    })


    // кнопка оформления заказа.
    $(document).on('click', '#checkout-btn', (e) => {
      e.preventDefault();
      const activeForm = $(document).find('form.payment-form:not(.d-none)');
      const activeFormName = $(activeForm).find('input[name=action]').val();
      const formData = new FormData(document.forms[`payment_method__${activeFormName}`]);
      const acceptConfidenceValue = $(document).find('input[name=accept-confidence]').is(':checked');
      formData.set('accept-confidence', acceptConfidenceValue);

      const acceptDocumentsNames = ['payment_method__delivery']
      if (acceptDocumentsNames.includes($('.payment-form:not(.d-none)').attr('name'))) {
        const needDocuments = $('#checkout-documents').is(':checked');
        formData.set('without-invoices', needDocuments);
      }

      const target = $('#checkout-btn')

      if (activeForm && validateForm(activeForm)) {
        target.prepend('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>')
        target.attr("disabled")
        $.ajax({
          url: '',
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          dataType: 'json',
          mimeType: 'multipart/form-data',
          async: false,
          success: (resp) => {
            const {location, message} = resp;
            if (message === 'success') window.location.href = location;
          },
          error: (err) => {
            console.log(err);
            const error_modal = $('#errorCreatedOrderModal');
            if ('responseJSON' in err && 'description' in err.responseJSON) {
              error_modal.find('.modal-body__ajax').removeClass('d-none').html(err.responseJSON.description);
              error_modal.find('.modal-body__default').addClass('d-none');
            } else if ('status' in err && 'statusText' in err) {
              error_modal.find('.modal-body__ajax').removeClass('d-none').html(err.status + ' ' + err.statusText);
            } else {
              error_modal.find('.modal-body__ajax').addClass('d-none').html('');
              error_modal.find('.modal-body__default').removeClass('d-none');
            }
            error_modal.modal('show');
          },
          done: () => {
            target.find('span.spinner-border')?.remove();
            target.removeAttr("disabled")
          }
        });
      }
    });

    $(document).on('hidden.bs.modal', '#errorCreatedOrderModal', (e) => {
      const error_modal = $('#errorCreatedOrderModal');
      error_modal.find('.modal-body__ajax').addClass('d-none').html('');
      error_modal.find('.modal-body__default').removeClass('d-none');
    });


    // Пагинация
    $(document).on('click', '.page-link', (e) => {
      e.preventDefault();
      const param = new URLSearchParams($(e.target).attr('href').toString());
      const paramName = 'page';
      const paramValue = param.get(paramName);
      appendQuery(paramName, paramValue);
    });

    // Сколько показывать товаров на страницу
    $(document).on('change', '#view-option-limit', (e) => {
      const paramValue = $(e.target).val();
      const paramName = 'per_page';
      appendQuery(paramName, paramValue);
    });

    $(document).on('change', '#view-option-sort', (e) => {
      const paramValue = $(e.target).val();
      const paramName = 'sort';
      appendQuery(paramName, paramValue);
    })

    // Сортировка


    // // кнопка поиска
    // $(document).on('click', '.search__button--end', (e) => {
    //   e.preventDefault();
    //   const activeForm = $(e.target).closest('form');
    //   const formData = new FormData(activeForm.get(0));
    //   const searchString = formData.get('search-string')
    //
    //
    //   $.ajax({
    //     url: '/products/search/?'+ $.param({ search: searchString }),
    //     type: 'GET',
    //     processData: false,
    //     contentType: false,
    //     mimeType: 'multipart/form-data',
    //     success: (response) => {
    //       const {products} = response;
    //       console.log(products)
    //       $('.products-list__content').html(products, this);
    //
    //     },
    //     error: (err) => {
    //       console.log(err);
    //     },
    //   });
    //
    // })

    // Загрузка контента по ajax в табы
    $(document).on('click', '.product-tabs__item-ajax a', (e) => {
      e.preventDefault();

      const entity = $(e.target).data('ajaxEntity');
      const entity_id = $(e.target).data('ajaxEntityId');
      const action = $(e.target).data('ajaxAction');
      let url = '/' + entity + '/';
      if (entity_id) url += entity_id + '/';
      if (action) url += '?action=' + action;

      const contentTargetEl = $($(e.target).attr('href'));

      if (!contentTargetEl.html().trim()) {
        contentTargetEl.html(spinnerHTML);

        $.ajax({
          url: languageURL(url), type: 'GET', mimeType: 'application/json', success: (response) => {
            const {context, status} = response;
            if (status === 'success') contentTargetEl.html(context);
          }, error: (err) => {
            console.log(err);
            contentTargetEl.html(renderReqErrTextHTML(err));
          },
        });
      }
    });

    // Фильтер транспортных средств в применимости
    $(document).on('change', '#applicabilityFilterManufacturer', (e) => {
      e.preventDefault();
      const entity_id = $(e.target).data('productId');
      const filter_manufacturer = $(e.target).val();
      let url = '/products/';
      if (entity_id) url += entity_id + '/';
      url += '?action=get_applicability';
      if (filter_manufacturer) url += '&filter_manufacturer=' + filter_manufacturer;

      const contentTargetEl = $(e.target).closest('#product-tab-applicability');

      contentTargetEl.html(spinnerHTML);

      $.ajax({
        url: languageURL(url), type: 'GET', mimeType: 'application/json', success: (response) => {
          const {context, status} = response;
          if (status === 'success') contentTargetEl.html(context);
        }, error: (err) => {
          console.log(err);
          contentTargetEl.html(renderReqErrTextHTML(err));
        },
      });
    });

    if (location.pathname.includes('order-xlsx')) {
      //Инициализация bs-custom-file-input
      bsCustomFileInput.init();
      // Очистка формы с файлом
      $('#upload-xlsx-file').closest('form').get(0).reset();
      // Загрузка файла xlsx
      $(document).on('change', '#upload-xlsx-file', (e) => {
        e.preventDefault();
        const activeForm = $(e.target).closest('form');
        const formData = new FormData(activeForm.get(0));
        formData.set('action', 'read_xlsx');
        const file = e.target.files[0];
        const size = file.size;
        const type = file.type ? file.type : 'NA';
        const support_type_files = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        const progress = activeForm.find('.progress');
        const progress_bar = activeForm.find('.progress-bar');
        progress.hide();
        progress_bar.attr('aria-valuenow', '0').text('0%').width('0%');
        progress_bar.removeClass('bg-success').addClass('bg-danger');
        const progress_bar_text_trans = {
          'ua': 'Сервер обробляє інформацію ...',
          'ru': 'Cервер обрабатывает информацию...',
          'en': 'The server processes information ...',
        };
        let lang_key = 'ua';
        for (const language of languages) {
          if (window.location.pathname.split('/').includes(language)) {
            lang_key = language.toLowerCase();
            break;
          }
        }
        const progress_bar_text = progress_bar_text_trans[lang_key];

        if ((size > 1024 * 1024) || !(support_type_files.indexOf(type) + 1)) {
          $(e.target).addClass('is-invalid');
        } else {
          $(e.target).removeClass('is-invalid');

          progress.fadeIn(400);

          $.ajax({
            url: '',
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json',
            mimeType: 'multipart/form-data',
            xhr: () => {
              let xhr = new window.XMLHttpRequest();
              //Upload progress
              xhr.upload.addEventListener("progress", (e) => {
                if (e.lengthComputable) {
                  if (e.loaded && e.total) {
                    const percent = (e.loaded / e.total) * 100;
                    const percent_text = Math.round(percent) + '%';
                    progress_bar.attr('aria-valuenow', percent).text(percent_text).width(percent_text);
                    if (percent === 100) {
                      setTimeout(function () {
                        if (progress_bar.text() === "100%") {
                          progress_bar.text(progress_bar_text).addClass('progress-blink');
                        }
                      }, 1000);
                    }
                  }
                }
              }, false);
              return xhr;
            },
            success: (response) => {
              const {location, status} = response;
              if (status === 'success') {
                setTimeout(function () {
                  progress_bar.removeClass('bg-danger').addClass('bg-success');
                  setTimeout(function () {
                    activeForm.get(0).reset();
                    progress.fadeOut(600);
                    window.location.href = location;
                  }, 1000);
                }, 1000);
              }
            },
            error: (err) => {
              console.log(err);
              showReqErrAlert();
            },
          });
        }
      });
    }


    // Модалка для выбора контрагентов
    $(document).on('show.bs.modal', '#selectPartnerModal', (e) => {
      const modal_body = $(e.target).find('.modal-body');
      modal_body.html(spinnerHTML);
      $.ajax({
        url: languageURL('/account/counterparties/'),
        type: 'GET',
        mimeType: 'application/json',
        success: (response) => {
          const {context, status} = response;
          if (status === 'success') modal_body.html(context).find('.form-control-select2').select2();
        },
        error: (err) => {
          console.log(err);
          modal_body.html(renderReqErrTextHTML(err));
        },
      });
    });

    $(document).on('change', '.set-partner-contract #select-partner', (e) => {
      e.preventDefault();
      const partner_id = $(e.target).val();
      const set_partner_contract_parent = $(e.target).closest('.set-partner-contract').parent();
      set_partner_contract_parent.html(spinnerHTML);

      $.ajax({
        url: languageURL('/account/counterparties/?action=get_partner_contract&select_partner=' + partner_id),
        type: 'GET',
        mimeType: 'application/json',
        success: (response) => {
          const {context, status} = response;
          if (status === 'success') set_partner_contract_parent.html(context).find('.form-control-select2').select2();
        },
        error: (err) => {
          console.log(err);
          set_partner_contract_parent.html(renderReqErrTextHTML(err));
        },
      });
    });

    $(document).on('change', '.set-partner-contract #select-partner-contract', (e) => {
      e.preventDefault();
      const partner_contract_id = $(e.target).val();
      const set_partner_contract_parent = $(e.target).closest('.set-partner-contract').parent();
      const set_partner_btn = set_partner_contract_parent.find('#set-partner-btn');
      const partner_id = set_partner_contract_parent.find('#select-partner').val();
      const reqUrl = location.pathname;
      if (partner_id && partner_contract_id) set_partner_btn.prop('disabled', false); else set_partner_btn.prop('disabled', true);
    });

    $(document).on('click', '.set-partner-contract #set-partner-btn', (e) => {
      e.preventDefault();
      const set_partner_btn = $(e.target);
      const set_partner_contract_parent = $(e.target).closest('.set-partner-contract').parent();
      const partner_id = set_partner_contract_parent.find('#select-partner').val();
      const partner_contract_id = set_partner_contract_parent.find('#select-partner-contract').val();
      const reqUrl = location.pathname;
      if (partner_id && partner_contract_id) {
        $.ajax({
          url: languageURL('/account/counterparties/'), type: 'POST', data: {
            'action': 'set_partner',
            'select_partner': partner_id,
            'selected-contract': partner_contract_id,
            'reqUrl': reqUrl,
          }, mimeType: 'application/json', success: (response) => {
            const {location, status} = response;
            if (status === 'success') {
              window.location.href = location;
            }
          }, error: (err) => {
            console.log(err);
            set_partner_contract_parent.html(renderReqErrTextHTML(err));
          },
        });
      } else set_partner_btn.prop('disabled', true);
    });


    $(document).on('click', '#refresh-captcha', (e) => {
      e.preventDefault();
      const contentTargetEl = $('#contact-form .capcha-wrapper');
      const url = '';
      $.ajax({
        url: languageURL(url),
        type: 'GET',
        data: {
          'action': 'refresh_captcha',
        },
        mimeType: 'application/json',
        success: (response) => {
          const {context, status} = response;
          if (status === 'success') contentTargetEl.html(context);
        },
        error: (err) => {
          console.log(err);
          showReqErrAlert();
        },
      });
    });


    $(document).on('click', '#contact-form-send-button', (e) => {
      e.preventDefault();
      const activeForm = $(e.target).closest('form');
      const activeFormWrapper = activeForm.closest('div');
      const formData = new FormData(activeForm.get(0));
      $.ajax({
        url: '',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success: (resp) => {

         if (resp.status === 'success') {
            activeForm.trigger('reset').addClass('d-none');
            activeFormWrapper
              .find('.send-result')
              .addClass('d-flex')
              .removeClass('d-none')
              .html($(`<p>${resp.context}</p>`));
            console.log('Message sent successfully!');
          }
          else if(resp.status === 'failing') showReqErrAlert(resp.text, '', 10);
          else showReqErrAlert();

        },
        error: (err) => {
          console.error(err);
          showReqErrAlert();
        }
      });
    });


  });
})($);


// Need to be refactored
if (location.pathname.includes('dropshipping_wallet')) {
  $(document).on('click', '.dropshipping-wallet__section .section__item .item__right-side .right-side__button', (e) => {
    const target_block = $(e.target).parent('.item__right-side').find('.right-side__dropdown-content');
    $('.right-side__dropdown-content_opened').not(target_block)
      .removeClass('right-side__dropdown-content_opened').addClass('d-none');
    if (target_block.hasClass('right-side__dropdown-content_opened')) {
      target_block.removeClass('right-side__dropdown-content_opened').delay(100).queue(function (next) {
        $(this).addClass('d-none');
        next();
      });
    } else {
      target_block.removeClass('d-none').delay(100).queue(function (next) {
        $(this).addClass('right-side__dropdown-content_opened');
        next();
      });
    }
    // $(e.target).parent().find('.right-side__dropdown-content').toggleClass('right-side__dropdown-content_opened')
  });

  $(document).on('click', function (e) {
    // if ($(e.target).hasClass('right-side__button')) return
    // $('.right-side__dropdown-content')
    //   .not($(e.target).closest('.right-side__dropdown-content'))
    //   .removeClass('right-side__dropdown-content_opened');
    if ($(e.target).hasClass('right-side__button')) return;
    if ($(e.target).closest('.right-side__dropdown-content_opened').length) return;
    $('.right-side__dropdown-content_opened')
      .removeClass('right-side__dropdown-content_opened').delay(100).queue(function (next) {
      $(this).addClass('d-none');
      next();
    });
  });

  $(document).on('click', '.right-side__dropdown-content .dropdown-content__table button', function () {
    const margin = $(this).data('margin')
    const transformedMargin = Math.abs(parseFloat(margin.replace(',', '.')))
    $('#agreementText').text($(this).data('name'));
    $('#modalInput2').val(transformedMargin);
    $('#transferButton').data('order-id', $(this).data('order-id'))
    $('#transferButton').data('contract-id', $(this).data('contract-id'))
  });

  $(document).on('click', '#transferButton', function () {
    const formData = new FormData();
    formData.set('order_id', $(this).data('order-id'));
    formData.set('contract_id ', $(this).data('contract-id'));
    formData.set('action ', 'money_transfer');

    $.ajax({
      url: '',
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      mimeType: 'multipart/form-data',
      success: (response) => {

        const {accrual_card} = JSON.parse(response);

        $(`[data-order-id="${$(this).data('order-id')}"]`).replaceWith(accrual_card) // Заменяем карточку

        $('#dropshipTransferModal').modal('hide'); // Закрываем модальное окно
      },
      error: (err) => {
        console.log(err);
        showReqErrAlert();
      },
    });
  });


}

const renderReqErrTextHTML = (ajax_err) => {
  let error_text = '';
  if ('responseJSON' in ajax_err && 'description' in ajax_err.responseJSON) {
    error_text = ajax_err.responseJSON.description;
  } else if ('status' in ajax_err && 'statusText' in ajax_err) {
    error_text = ajax_err.status + ' ' + ajax_err.statusText;
  } else {
    const alert_headings = {
      'ua': 'Ой! Щось пішло не так!', 'ru': 'Ой! Что-то пошло не так!', 'en': 'Oops! What was not so!',
    };
    let lang_key = 'ua';
    for (const language of languages) {
      if (window.location.pathname.split('/').includes(language)) {
        lang_key = language.toLowerCase();
        break;
      }
    }
    error_text = alert_headings[lang_key];
  }
  return `<div class="d-flex justify-content-center">${error_text}</div>`;
}

const showReqErrAlert = (alert_msg, statusCode, sec) => {
  let top = 30;
  let z_index = 1000;
  // if(!alert_msg) alert_msg = 'Lorem ipsum dolor sit amet consectetur, adipisicing, elit!';
  if (!alert_msg) alert_msg = '';
  if (!sec) sec = 5;
  const strong_string = (statusCode ? `<strong>${statusCode}: </strong>` : '');
  const alert_headings = {
    'ua': 'Ой! Щось пішло не так!', 'ru': 'Ой! Что-то пошло не так!', 'en': 'Oops! What was not so!',
  };
  let lang_key = 'ua';
  for (const language of languages) {
    if (window.location.pathname.split('/').includes(language)) {
      lang_key = language.toLowerCase();
      break;
    }
  }
  const alert_heading = alert_headings[lang_key];
  const last_alert = $('.alert').last();
  if (last_alert.length) {
    // console.log(last_alert.position().top)
    // console.log(last_alert.outerHeight())
    top = last_alert.position().top + last_alert.outerHeight() + 10;
    // console.log(top)
    if (top > ($('body').height() / 3 * 2)) top = 30;
    z_index = last_alert.css('z-index') + 1;
  }
  const new_alert = $(`<div role="alert" style="position: fixed;right: 30px;top: ${top}px;z-index: ${z_index};max-width: 500px; display: none" class="alert alert-danger alert-dismissible show fade">
    <h4 class="alert-heading">${alert_heading}</h4>
    <hr>
    <p>${strong_string}${alert_msg}</p>
    <button type="button" class="close pt-2" data-dismiss="alert" aria-label="Close" style="height: inherit;">
      <span aria-hidden="true">×</span>
    </button>
  </div>`);
  $('body').append(new_alert);
  new_alert.slideDown().delay((sec * 1000)).queue(function (next) {
    $(this).alert('close');
    next();
  });
}
// Закоментил т.к. вернул работу штатного плагина photoswipe

// $(document).on('click', '[data-action="zoom"], #zoom-container', function (e) {
//   $('#zoom-container').toggleClass('zoom-active')
//   $('#zoom-container img').attr('src', $(e.target).attr('src'))
// });

// $(document).on('click', '#zoom-container', function (e) {
//   $('#zoom-container').removeClass('zoom-active')
// });

// $(document).on('click', '.product-gallery__zoom', function () {
//   $('#zoom-container').toggleClass('zoom-active')
//   $('#zoom-container img').attr('src', $('.product-gallery__featured .owl-stage .active img').attr('src'))
// });
